import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Leaderboard from './Leaderboard';
import About from './About';
import TeamLogin from './TeamLogin';
import CommissionerLogin from './CommissionerLogin';
import PowerRankings from './PowerRankings';

function App() {
  return (
    <Router>
      <div className="header-text">
        Clemson Wiffle Ball Club
      </div>
      <div className="App">
        <nav>
          <ul className="nav-links">
            <li>
              <Link to="/">About</Link>
            </li>
            <li>
              <Link to="/stats">Stats</Link>
            </li>
            <li>
              <Link to="/power-rankings">Power Rankings</Link>
            </li>
            <li>
              <Link to="/team-login">Team Login</Link>
            </li>
          </ul>
        </nav>
        <div className="main-content">
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/stats" element={<Leaderboard />} />
            <Route path="/team-login" element={<TeamLogin />} />
            <Route path="/power-rankings" element={<PowerRankings />} />
            <Route path="/commissioner-login" element={<CommissionerLogin />} />          
            </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
