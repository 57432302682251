import React, { useState } from 'react';
import axios from '../api/axios';
import TeamDashboard from './TeamDashboard';
import './TeamDashboard.css';

function TeamLogin() {
  const [teamCode, setTeamCode] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('Attempting to log in with team code:', teamCode);
    try {
      const response = await axios.post('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/team/login', { teamCode });
      console.log('Response:', response);
      if (response.status === 200) {
        setIsAuthenticated(true);
        setMessage('');
        console.log('Login successful');
      }
    } catch (err) {
      setMessage('Invalid team code');
      console.error('Error logging in:', err);
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        <TeamDashboard teamCode={teamCode} />
      ) : (
        <div>
          <h2>Team Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Team Code"
              value={teamCode}
              onChange={(e) => setTeamCode(e.target.value)}
            />
            <button type="submit" className = 'login-button'>Login</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      )}
    </div>
  );
}

export default TeamLogin;
