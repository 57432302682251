import React, { useState, useEffect } from 'react';
import axios from '../api/axios';

function CommissionerLogin() {
  const [teamCode, setTeamCode] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamDay, setTeamDay] = useState('Sunday'); // Default to Sunday
  const [message, setMessage] = useState('');
  const [selectedDay, setSelectedDay] = useState('Sunday');
  const [teams, setTeams] = useState([]);
  const [winnerTeam, setWinnerTeam] = useState('');
  const [loserTeam, setLoserTeam] = useState('');
  const [setSelectedTeam] = useState(null);

  useEffect(() => {
    if (selectedDay) {
        fetchTeamsForDay(selectedDay);
    }
}, [selectedDay]);

const fetchTeamsForDay = async (day) => {
  try {
      const response = await axios.get(`/api/teams?day=${day}`);
      setTeams(response.data);
  } catch (error) {
      console.error("Error fetching teams:", error);
  }
};

  const fetchTeamsByDay = async (day) => {
    try {
      const response = await axios.get(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/teams?day=${day}`);
      setTeams(response.data);
    } catch (err) {
      console.error('Error fetching teams:', err);
    }
  };

  const handleAddTeam = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/teams/add', { teamCode, teamName, day: teamDay });
      setMessage(`Team ${response.data.teamName} added successfully!`);
      setTeamCode('');
      setTeamName('');
      setTeamDay('Sunday'); // Reset to default
    } catch (err) {
      setMessage(err.response.data.message || 'Error adding team');
      console.error(err);
    }
  };

  const handleSetDay = async (e) => {
    e.preventDefault();
    console.log('Updating team day:', { teamCode, teamDay });
    try {
      const response = await axios.post(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/team/${teamCode}/setDay`, { day: teamDay });
      setMessage(`Team day updated successfully to ${teamDay}!`);
      console.log('Response:', response);
    } catch (err) {
      setMessage(err.response.data.message || 'Error updating team day');
      console.error('Error updating team day:', err);
    }
  };

  const handleDeleteTeam = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/team/${teamCode}`);
      setMessage(response.data.message);
      setTeamCode('');
    } catch (err) {
      setMessage(err.response?.data?.message || 'Error deleting team');
      console.error(err);
    }
  };

  const handleDeleteAllTeams = async () => {
    try {
      const response = await axios.delete('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/teams');
      setMessage(response.data.message);
    } catch (err) {
      setMessage(err.response?.data?.message || 'Error deleting all teams');
      console.error(err);
    }
  };

  const handleDayChange = async (e) => {
    const selectedDay = e.target.value;
    setSelectedDay(selectedDay);
    try {
      const response = await axios.get(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/teams?day=${selectedDay}`);
      console.log('Fetched teams:', response.data); // Log fetched teams
      setTeams(response.data);
      setSelectedTeam('');
    } catch (err) {
      console.error('Error fetching teams:', err);
    }
  };

  const handleMatchResult = async () => {
    if (!winnerTeam || !loserTeam) {
      setMessage('Please select both winner and loser teams.');
      return;
    }
    if (winnerTeam === loserTeam) {
      setMessage('Winner and loser cannot be the same team.');
      return;
    }
    try {
      await axios.post('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/update-elo', {
        winnerTeamCode: winnerTeam,
        loserTeamCode: loserTeam,
      });
      setMessage('Match result recorded successfully, and Elo ratings updated.');
      fetchTeamsByDay(selectedDay); // Refresh teams after updating Elo ratings
    } catch (err) {
      setMessage('Error recording match result');
      console.error('Error updating Elo ratings:', err);
    }
  };

  return (
    <div>
        <div>
          <h2>Add New Team</h2>
          <form onSubmit={handleAddTeam}>
            <input
              type="text"
              placeholder="Team Code"
              value={teamCode}
              onChange={(e) => setTeamCode(e.target.value)}
            />
            <input
              type="text"
              placeholder="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <select value={teamDay} onChange={(e) => setTeamDay(e.target.value)}>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
            </select>
            <button type="submit">Add Team</button>
          </form>

          <h2>Record Match Result</h2>
        <select value={selectedDay} onChange={handleDayChange}>
          <option value="Sunday">Sunday</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
        </select>
        <div>
          <h3>Select Winner and Loser Teams</h3>
          <select value={winnerTeam} onChange={(e) => setWinnerTeam(e.target.value)}>
            <option value="">Select Winner</option>
            {teams.map((team) => (
              <option key={team._id} value={team.teamCode}>
                {team.teamName}
              </option>
            ))}
          </select>
          <select value={loserTeam} onChange={(e) => setLoserTeam(e.target.value)}>
            <option value="">Select Loser</option>
            {teams.map((team) => (
              <option key={team._id} value={team.teamCode}>
                {team.teamName}
              </option>
            ))}
          </select>
          <button onClick={handleMatchResult}>Submit Match Result</button>
        </div>

          <h2>Set Team Day</h2>
          <form onSubmit={handleSetDay}>
            <input
              type="text"
              placeholder="Team Code"
              value={teamCode}
              onChange={(e) => setTeamCode(e.target.value)}
            />
            <select value={teamDay} onChange={(e) => setTeamDay(e.target.value)}>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
            </select>
            <button type="submit">Set Day</button>
          </form>

          <h2>Delete Team</h2>
          <form onSubmit={handleDeleteTeam}>
            <input
              type="text"
              placeholder="Team Code"
              value={teamCode}
              onChange={(e) => setTeamCode(e.target.value)}
            />
            <button type="submit">Delete Team</button>
          </form>
          
          <h2>Delete All Teams</h2>
          <button onClick={handleDeleteAllTeams}>Delete All Teams</button>
          
          {message && <p>{message}</p>}
        </div>

    </div>
  );
}

export default CommissionerLogin;
