import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://cuwiffleball-290d16e38d6e.herokuapp.com/api', // Change this to your backend URL
  headers: {
    'Content-Type': 'application/json'
  }
});

export default instance;
