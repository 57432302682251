import React, { useState, useEffect } from 'react';
import axios from '../api/axios';
import './TeamDashboard.css'; // Ensure you have a CSS file for styling

function TeamDashboard({ teamCode }) {
  const [team, setTeam] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [stats, setStats] = useState({ atBats: 0, walks: 0, singles: 0, doubles: 0, triples: 0, homeRuns: 0 });
  const [playerName, setPlayerName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 'playerName', direction: 'ascending' });

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/team/${teamCode}`);
        setTeam(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching team details:', err);
        setLoading(false);
      }
    };
    fetchTeamDetails();
  }, [teamCode]);

  const handleAddPlayer = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/team/${teamCode}/addPlayer`, { playerName });
      setTeam(response.data);
      setPlayerName('');
      setMessage('Player added successfully!');
    } catch (err) {
      console.error('Error adding player:', err);
      setMessage('Error adding player');
    }
  };

  const handleUpdateStats = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/team/${teamCode}/updateStats`, {
        playerName: selectedPlayer,
        ...stats
      });
      setTeam(response.data); // Update team state with new data
      setSelectedPlayer('');
      setStats({ atBats: 0, walks: 0, singles: 0, doubles: 0, triples: 0, homeRuns: 0 });
      setMessage('Stats updated successfully!');
    } catch (err) {
      console.error('Error updating stats:', err);
      setMessage('Error updating stats');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPlayers = React.useMemo(() => {
    if (!team || !team.stats) return [];
    const sortedArray = [...team.stats];
    sortedArray.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedArray;
  }, [team, sortConfig]);

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!team || !team.stats) {
    return <div>Error loading team details</div>;
  }

  return (
    <div className="team-dashboard">
      <h2>Team Dashboard</h2>
      <h3>Team: {team.teamName}</h3>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th onClick={() => handleSort('atBats')}>At Bats {getSortIndicator('atBats')}</th>
              <th onClick={() => handleSort('walks')}>Walks {getSortIndicator('walks')}</th>
              <th onClick={() => handleSort('singles')}>Singles {getSortIndicator('singles')}</th>
              <th onClick={() => handleSort('doubles')}>Doubles {getSortIndicator('doubles')}</th>
              <th onClick={() => handleSort('triples')}>Triples {getSortIndicator('triples')}</th>
              <th onClick={() => handleSort('homeRuns')}>Home Runs {getSortIndicator('homeRuns')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedPlayers.length > 0 ? (
              sortedPlayers.map((player, index) => (
                <tr key={index}>
                  <td>{player.playerName}</td>
                  <td>{player.atBats}</td>
                  <td>{player.walks}</td>
                  <td>{player.singles}</td>
                  <td>{player.doubles}</td>
                  <td>{player.triples}</td>
                  <td>{player.homeRuns}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No players found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <h3>Add Player</h3>
      <form onSubmit={handleAddPlayer}>
        <input
          type="text"
          placeholder="Player Name"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
        />
        <button type="submit" className='add-button'>Add Player</button>
      </form>

      <h3>Update Stats</h3>
      <form onSubmit={handleUpdateStats}>
        <div className="stat-row">
          <div className="select-player">
            <label htmlFor="selectPlayer">Select Player:</label>
            <select
              id="selectPlayer"
              value={selectedPlayer}
              onChange={(e) => setSelectedPlayer(e.target.value)}
              required
            >
              <option value="" disabled>Select Player</option>
              {team.stats.map((player, index) => (
                <option key={index} value={player.playerName}>
                  {player.playerName}
                </option>
              ))}
            </select>
          </div>
          <div className="stat-input">
            <label htmlFor="atBats">At Bats:</label>
            <input
              type="number"
              id="atBats"
              value={stats.atBats}
              onChange={(e) => setStats({ ...stats, atBats: parseInt(e.target.value) })}
              required
            />
          </div>
          <div className="stat-input">
            <label htmlFor="walks">Walks:</label>
            <input
              type="number"
              id="walks"
              value={stats.walks}
              onChange={(e) => setStats({ ...stats, walks: parseInt(e.target.value) })}
              required
            />
          </div>
          <div className="stat-input">
            <label htmlFor="singles">Singles:</label>
            <input
              type="number"
              id="singles"
              value={stats.singles}
              onChange={(e) => setStats({ ...stats, singles: parseInt(e.target.value) })}
              required
            />
          </div>
          <div className="stat-input">
            <label htmlFor="doubles">Doubles:</label>
            <input
              type="number"
              id="doubles"
              value={stats.doubles}
              onChange={(e) => setStats({ ...stats, doubles: parseInt(e.target.value) })}
              required
            />
          </div>
          <div className="stat-input">
            <label htmlFor="triples">Triples:</label>
            <input
              type="number"
              id="triples"
              value={stats.triples}
              onChange={(e) => setStats({ ...stats, triples: parseInt(e.target.value) })}
              required
            />
          </div>
          <div className="stat-input">
            <label htmlFor="homeRuns">Home Runs:</label>
            <input
              type="number"
              id="homeRuns"
              value={stats.homeRuns}
              onChange={(e) => setStats({ ...stats, homeRuns: parseInt(e.target.value) })}
              required
            />
          </div>
        </div>
        <button type="submit" className="update-button">Update Stats</button>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
}

export default TeamDashboard;
