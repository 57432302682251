import React, { useState } from 'react';
import axios from '../api/axios';
import CommissionerDashboard from './CommissionerDashboard';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/auth/login', { username, password });
      console.log('Login successful:', response.data);
      if (response.status === 200) {
        setIsAuthenticated(true);
        setMessage('');
        console.log('Login successful');
      }
    } catch (err) {
      setMessage('Error logging in');
      console.error('Error during login:', err);
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        <CommissionerDashboard/>
      ) : (
        <div>
          <h2>Commissioner Login</h2>
          <form onSubmit={handleLogin}>
           <input
             type="text"
             placeholder="Username"
             value={username}
             onChange={(e) => setUsername(e.target.value)}
          />
           <input
              type="password"
              placeholder="Password"
              value={password}
             onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
          {error && <p>{error}</p>}
        </form>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
