import React from 'react';
import './About.css'

function About() {
  return (
    <div className="outer-container">
      <div className = "inside-container">
        <h1>Welcome to Clemson Wiffle Ball Club!</h1>
        <p>
          Welcome to the Clemson Wiffle Ball Club! We are a community of wiffle ball enthusiasts who come together to play, compete, and have fun. Our club is open to players of all skill levels, from beginners to seasoned pros. We host regular games, tournaments, and events to promote the sport and foster a sense of camaraderie among our members. Join us for an exciting season of wiffle ball action!
        </p>
        <p>
          Our mission is to provide a fun and inclusive environment for wiffle ball players. We believe in fair play, sportsmanship, and creating lasting memories on the field. Whether you're looking to improve your skills, meet new friends, or simply enjoy the game, the Clemson Wiffle Ball Club is the place for you.
        </p>
      </div>
    </div>
  );
}

export default About;
