import React, { useState, useEffect } from 'react';
import axios from '../api/axios';
import './styles.css'; // Make sure to create this CSS file
//import Team from '../../models/Team';

function Leaderboard() {
  const [players, setPlayers] = useState([]);
  const [day, setDay] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'playerName', direction: 'ascending' });

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/players', {
          params: { day },
        });
        console.log('Fetched player stats:', response.data); // Log for debugging
        if (Array.isArray(response.data)) {
          setPlayers(response.data);
        } else {
          console.error('API response is not an array:', response.data);
          setPlayers([]); // Reset players to an empty array
        }
      } catch (err) {
        console.error('Error fetching player stats:', err.response || err.message); // Improved error logging
        setPlayers([]); // Reset players to an empty array
      }
    };
    fetchPlayers();
  }, [day]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPlayers = [...players].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↓' : '↑';
    }
    return '';
  };

  return (
    <div className="table-container">
      <h2>League Leaderboard</h2>
      <div>
        <label htmlFor="day-select">Select League Day:</label>
        <select
          id="day-select"
          value={day}
          onChange={(e) => setDay(e.target.value)}
        >
          <option value="">Entire League</option>
          <option value="Sunday">Sunday</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
          <th onClick={() => handleSort('teamName')}>Team {getSortIndicator('teamName')}</th>
            <th>Name</th>
            <th onClick={() => handleSort('totalAtBats')}>At Bats {getSortIndicator('totalAtBats')}</th>
            <th onClick={() => handleSort('totalWalks')}>Walks {getSortIndicator('totalWalks')}</th>
            <th onClick={() => handleSort('totalSingles')}>Singles {getSortIndicator('totalSingles')}</th>
            <th onClick={() => handleSort('totalDoubles')}>Doubles {getSortIndicator('totalDoubles')}</th>
            <th onClick={() => handleSort('totalTriples')}>Triples {getSortIndicator('totalTriples')}</th>
            <th onClick={() => handleSort('totalHomeRuns')}>Home Runs {getSortIndicator('totalHomeRuns')}</th>
            <th>BattingAVG </th>
            <th> OBP </th>
            <th>SLG</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(players) && players.length > 0 ? (
            sortedPlayers.map((player, index) => (
              <tr key={index}>
                <td>{player.teamName}</td>
                <td>{player.playerName}</td>
                <td>{player.totalAtBats}</td>
                <td>{player.totalWalks}</td>
                <td>{player.totalSingles}</td>
                <td>{player.totalDoubles}</td>
                <td>{player.totalTriples}</td>
                <td>{player.totalHomeRuns}</td>
                <td>{((player.totalSingles + player.totalDoubles + player.totalTriples + player.totalHomeRuns) / player.totalAtBats).toFixed(3)}</td>
                <td>{((player.totalSingles + player.totalDoubles + player.totalTriples + player.totalHomeRuns + player.totalWalks) / (player.totalAtBats + player.totalWalks)).toFixed(3)}</td>
                <td>{(((player.totalSingles + (2 * player.totalDoubles) + (3 * player.totalTriples) + (4 * player.totalHomeRuns))) / player.totalAtBats).toFixed(3)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No players found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;
